<template>
  <div class='system-user mainContainer'>
    <!--导航面包屑-->
    <bread-crumb></bread-crumb>
    <div class="searchCont mb15">
      <div class="title_name"><div>检索条件</div></div>
      <el-form ref="searchForm" :model="keyWord" label-width="140px" inline @submit.native.prevent size="small">
        <el-form-item label="登录名称/用户名称:" prop="name">
          <el-input type="text" class="w180" v-model="keyWord.name" placeholder="请输入登录名称/用户名称" clearable @keyup.enter.native="getList(1)"/>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="getList(1)" size="small">搜索</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="pageCont">
      <div class="title_name">
        <div>列表详情</div>
        <div>
          <el-button type="text" @click="openDio('add')" v-if="isAuth('safe:system:user:add')"><i class="el-icon-circle-plus-outline"></i>添加用户</el-button>
          <!-- <el-button type="text" @click="userSyncUser" v-if="isAdmin"><i class="el-icon-refresh"></i>同步数据</el-button> -->
          <el-button type="text" @click="batchEdit()" v-if="isAuth('safe:system:user:edit')"><i class="el-icon-edit"></i>批量修改</el-button>
          <el-button type="text" class="error" @click="batchDel()" v-if="isAuth('safe:system:user:del')"><i class="el-icon-delete"></i>批量删除</el-button>
        </div>
      </div>
      <div class="tableDivPage mt10">
        <el-table class="tableCont" :data="tableData" stripe :height="tableH" style="width:100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed></el-table-column>
          <el-table-column prop="account" label="账号名称"/>
          <el-table-column :formatter="formatEna" label="是否启用" width="80"/>
          <el-table-column :formatter="formatLock" label="是否临时冻结" width="110"/>
          <el-table-column :formatter="formatRoles" label="角色"/>
          <!-- <el-table-column prop="userTypeName" label="类型" width="80"/> -->
          <el-table-column prop="depaName" label="企业"/>
          <el-table-column :formatter="formatPerson" label="用户"/>
          <el-table-column :formatter="formatStatus" label="状态" width="80"/>
          <el-table-column prop="lastLoginIp" label="登录IP" width="120"/>
          <el-table-column :formatter="formatLastTime" label="登录时间" width="150"/>
          <el-table-column prop="remark" label="备注"/>
          <el-table-column label="操作" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" class="primary" @click="openDio('edit',scope.row)" v-if="isAuth('safe:system:user:edit')"><i class="el-icon-edit"></i>修改</el-button>
              <el-button type="text" class="primary" @click="openDio('reset',scope.row)" v-if="isAuth('safe:system:user:reset')"><i class="el-icon-refresh-left"></i>重置密码</el-button>
              <el-button type="text" class="error" @click="openDio('del',scope.row)" v-if="isAuth('safe:system:user:del')"><i class="el-icon-delete"></i>删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pageDiv">
        <el-pagination
          :current-page="curr"
          :page-size="limits"
          background
          layout="total,sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100,200]"
          :total="total"
          @size-change="sizeChange"
          @current-change="currChange"
        />
      </div>
    </div>

    <!--创建、修改-->
    <user-add-edit ref="editDio" @addEditSucc="getList()"></user-add-edit>

  </div>
</template>
<script>
import breadCrumb from '@/components/bread-crumb.vue'
import userAddEdit from './user-add-edit.vue'
import { apiUrl } from '@/assets/js/api';
import qs from 'qs'
import { formatTime} from '@/utils/utils'
var vm;
export default {
  name:'system-user',
  data() {
    return {
      //筛选数据
      keyWord :{
        name:'',
      },
      //分页
      curr :1,//当前页
      limits :10,//每页容量
      total :0,//数据总数
      
      tableH :'',//表格高度
      tableActive:[],//表格选中
      tableData :[],

      isAdmin:false,//是否是超级管理员
    }
  },
  components:{
    userAddEdit,
    breadCrumb,
  },
  created(){
    vm = this
    vm.initHeight()
    let userInfo = this.vtp.get('userInfo')
    if(userInfo){
      this.isAdmin = userInfo.isAdmin
    }
  },
  mounted(){
    vm.getList()
    
    window.onresize=() => {
      vm.initHeight()
    }
  },

  //方法
  methods:{
    //同步用户
    async userSyncUser(){
      const res = await apiUrl.userSyncUser()
      if(res?.code==200){
        vm.$message.success('同步成功')
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //表格多选
    handleSelectionChange(val) {
      vm.tableActive = val;
    },
    //打开弹窗
    openDio (type,item){
      if(type == 'del'){
        vm.$confirm('确定删除该选项','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
          .then(() => {
            let arr=[]
            if(item){
              arr.push(item.id)
            }
            vm.deleteUser(arr)
          })
          .catch(() => {})
      }else if(type=='reset'){//重置密码
        vm.$confirm('是否重置该账号密码','提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        ).then(() => {
          vm.userResetPwd(item.id)
        })
        .catch(() => {})
      }else{
        if(type == 'add'||type=='edit'){
          vm.$refs.editDio.init(type,item);//新增或修改
        }
      }
    },
    //重置密码
    async userResetPwd(id){
      const res = await apiUrl.userResetPwd(id)
      if(res?.code==200){
        vm.$message.success('重置成功')
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //批量修改
    batchEdit(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      let arr = []
      for(var i in vm.tableActive){
        arr.push(vm.tableActive[i].id)
      }
      vm.$refs.editDio.init('batch',arr)
    },
    //批量删除
    batchDel(){
      if(vm.tableActive.length==0){
        vm.$message.warning('请勾选需要操作的数据')
        return false
      }
      this.$confirm('是否确定要删除所选数据，删除后不可恢复？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let arr = []
        for(var i in vm.tableActive){
          arr.push(vm.tableActive[i].id)
        }
        vm.deleteUser(arr);//删除
      }).catch(() => {});
    },
    //删除用户
    async deleteUser(ids){
      let dataObj = {
        'data':ids
      }
      const res = await apiUrl.deleteUser(dataObj)
      if(res?.code==200){
        vm.$message.success('删除成功')
        let totalPage = Math.ceil((this.total - ids.length) / this.limits);
        let current = this.curr > totalPage ? totalPage : this.curr;
        this.curr = current < 1 ? 1 : current;
        vm.getList()
      }else{
        vm.$message.error(res.message)
      }
    },
    //过滤状态
    formatStatus (row, column){
      return row.isOnline==0?<span class="info">离线</span>:<span class="success">在线</span>
    },
    //过滤是否启用
    formatEna (row, column){
      return row.isEnable==1?'是':'否'
    },
    //过滤是否临时冻结
    formatLock (row, column){
      return row.isLocked==1?'是':'否'
    },
    //过滤时间
    formatLastTime(row,column){
      return row.lastLoginTime?formatTime(row.lastLoginTime,'Y-M-D h:m:s'):'-'
    },
    //过滤企业
    formatDepat(row,column){
      let arr = []
      if(row.departments && row.departments.length>0){
        for(var i in row.departments){
          arr.push(row.departments[i].depaName)
        }
      }
      return arr.join(',')||'-'
    },
    //过滤用户
    formatPerson(row,column){
      let str = ''
      if(row.personVo){
        str = row.personVo.personName
      }
      return str||'-'
    },
    //过滤角色
    formatRoles(row, column){
      if(row.roles){
        var strName = []
        for(var i in row.roles){
          strName.push(row.roles[i].roleName)
        }
        strName = strName.join('，')
        return strName
      }else{
        return '-'
      }
    },
    //分页选择
    sizeChange (val){
      vm.limits = val
      vm.getList(vm.curr,val)
    },
    currChange (val){
      vm.curr = val
      vm.getList(val)
    },
    //获取列表
    async getList (_curr,_limits){
      let dataObj = {
        'keyword': vm.keyWord.name,
        'pageNo': _curr?_curr:vm.curr,
        'pageSize': _limits?_limits:vm.limits
      }
      const res = await apiUrl.userListPage(dataObj)
      if(res?.code==200){
        vm.tableData = res.data.list
        vm.total = parseInt(res.data.totalSize)
        vm.curr = _curr?_curr:vm.curr
      }else{
        vm.$message.error(res.message)
      }
    },
    //设置高度
    initHeight (){
      var minWin = window.innerHeight;
      vm.tableH = minWin-322
    }
  }
}
</script>
<style lang='scss'>
.system-user{

}
</style>
