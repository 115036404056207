<template>
  <div class="userAddEdit">
    <el-dialog :title="dioTit" :visible.sync="isDio" :width="dioWidth" :close-on-click-modal="false" @close="closeDio('dioform')">
      <div class="contDio">
        <el-form ref="dioform" :rules="rules" :model="inform" label-width="100px" size="small">
        <template v-if="dioType!='batch'">
          <div style="position: fixed; left: -500px;top: 0;opacity: 0;">
            <el-input type="text"></el-input>
            <el-input type="password"></el-input>
          </div>
          <el-form-item label="登录账号:" prop="account">
            <el-input type="text" v-model="inform.account" :disabled="dioType=='edit'" placeholder="请输入登录账号" autocomplete="off"></el-input>
          </el-form-item>
          <template v-if="dioType == 'add'">
          <el-form-item label="登录密码:" prop="pwd">
            <el-input v-model="inform.pwd" type="password" placeholder="请输入登录密码" autocomplete="new-password"></el-input>
          </el-form-item>
          <el-form-item label="确认密码:" prop="confirmPwd">
            <el-input v-model="inform.confirmPwd" type="password" placeholder="请输入确认" autocomplete="new-password"></el-input>
          </el-form-item>
          </template>
          <!-- <el-form-item label="SIP账号:" prop="sipAccount">
            <el-input type="text" v-model="inform.sipAccount" placeholder="请输入SIP账号" autocomplete="off"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="SIP账号:" prop="sipAccount">
            <el-select v-model="inform.sipAccount" placeholder="请选择SIP账号" @change="sipChange">
              <el-option v-for="(item,index) in userList" :key="index" :label="item.sipAccount+'('+item.sipAccountType+')'" :value="item.sipAccount"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="SIP密码:" prop="sipPwd">
            <el-input v-model="inform.sipPwd" type="password" placeholder="请输入SIP密码" autocomplete="new-password"></el-input>
          </el-form-item> -->
        </template>
          <el-form-item label="企业:" prop="depaId">
            <el-cascader v-model="inform.depaId" :options="departList" :props="cascProps" :show-all-levels="false"/>
          </el-form-item>
          <el-form-item label="角色:" prop="roleIds">
            <el-select v-model="inform.roleIds" placeholder="请选择角色" multiple collapse-tags>
              <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id"/>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="登录支持:" prop="loginSupport">
            <el-radio-group v-model="inform.loginSupport">
              <el-radio label="SINGLE">单用户</el-radio>
              <el-radio label="MULTIPLE">多用户</el-radio>
            </el-radio-group>
          </el-form-item> -->
        <template v-if="dioType!='batch'">
          <el-form-item label="是否启用:" prop="isEnable">
            <el-checkbox v-model="inform.isEnable" label="启用" :true-label="1" :false-label="0"/>
          </el-form-item>
          <el-form-item label="临时冻结:" prop="isLocked">
            <el-checkbox v-model="inform.isLocked" label="是" :true-label="1" :false-label="0"/>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input type="textarea" :rows="5" v-model="inform.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
        </template>
        </el-form>
      </div>
      <span slot="footer">
        <el-button @click="closeDio('dioform')" size="small">关闭</el-button>
        <el-button type="primary" @click="checkForm('dioform')" size="small">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { apiUrl } from '@/assets/js/api'
import qs from 'qs'
import {delChildren} from '@/utils/utils'
var vm;
export default {
  data() {
    //验证form格式
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('确认密码不能为空'))
      } else if (value !== this.inform.pwd) {
        callback(new Error("确认密码与密码不一致"))
      } else {
        callback()
      }
    }
    return {

      isDio:false,//弹窗类型
      dioTit :'',//弹窗标题
      dioType :'',//弹窗类型
      dioWidth :'',//弹窗宽度

      //form信息
      inform :{
        id:'',
        account:'',//账号
        pwd:'',//密码
        confirmPwd:'',//确认密码
        sipAccount:'',//SIP账号
        sipPwd:'',//SIP密码
        roleIds:[],//角色
        depaId:'',//企业IDs
        isEnable:0,//是否启用
        isLocked:0,//临时冻结
        // loginSupport:'SINGLE',//登录支持
        remark:'',//备注
        userType:'',//用户类型
      },
      roleList :[],//角色数据
      departList :[],//企业数据
      userList:[],//sip账号数据

      userIds:[],//批量修改时的用户id数组

      cascProps: {
        value:'id',
        label:'depaName',
        children:'childDepartments',
        checkStrictly: true,
        emitPath:false,
      },

      
      rules :{
        account: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min:4, message:'长度不能低于4位', trigger:'blur'},
          { pattern : /^[A-Za-z0-9]+$/g, message:'请输入字母、数字格式的字符'}
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          //{ min:8,max:20, message:'长度不能低于8-20位', trigger:'blur'},
          //{ pattern : /^[A-Za-z0-9]+$/g, message:'请输入字母、数字格式的字符'},
          { pattern : '^(?=.*[a-zA-Z])(?=.*\\d)(?=.*[!@#$%^&*]).{8,20}$', message:'密码需包含字母、数字、特殊字符(!@#$%^&*)，长度8-20个字符'}
        ],
        confirmPwd: [
          { required: true, validator: validatePass, trigger: "blur" }
        ],
        sipAccount: [
          { required: true, message: '请选择SIP账号', trigger: 'change' }
        ],
        sipPwd: [
          { required: true, message: '请输入SIP密码', trigger: 'blur' }
        ],
        roleIds: [
          { required: true, message: '请选择角色', trigger: "change" }
        ],
        depaId: [
          { required: true, message: '请选择企业', trigger: "change" }
        ],
      },
    }
  },
  created(){
    vm = this
  },
  mounted(){
    vm.getDepartmentTree()
    vm.roleListFun()
  },

  //方法
  methods: {
    //初始化
    init (type,item){
      // console.log(item);
      vm.dioType = type
      vm.dioWidth = '640px'
      vm.isDio = true

      vm.$nextTick(() => {
        if(type == 'add'){
          vm.dioTit = '创建用户'
        }else if(type == 'edit'){
          vm.dioTit = '编辑用户'

          vm.inform.roleIds = item.roles?.map(value=>{
            return value.id;
          })//角色
          vm.inform.depaId = item.depaId//企业
          vm.inform.id = item.id
          vm.inform.account=item.account//账号
          vm.inform.sipAccount=item.sipAccount//SIP账号
          vm.inform.sipPwd=item.sipPwd//SIP密码
          vm.inform.isEnable=item.isEnable//是否启用
          vm.inform.isLocked=item.isLocked//是否临时冻结
          // inform.loginSupport=item.loginSupport||'SINGLE'//登录支持
          vm.inform.remark=item.remark//备注
          vm.inform.userType=item.userType//类型
        }else if(type=='batch'){
          vm.dioTit = '批量修改企业/角色'
          vm.userIds = item
        }
        // vm.userListUnbind()
      })
    },
    //sip账号改变
    sipChange(val){
      // console.log(val);
      let str;
      str = vm.userList?.find((li)=>{
        return li.sipAccount == val;//筛选出匹配数据
      });
      if(str){
        vm.inform.userType = str.sipAccountType
      }
    },
    //校验form
    checkForm (formEl){
      vm.$refs[formEl].validate((valid) => {
        if (valid) {
          if(vm.dioType=='add'){
            vm.addUserInsert()
          }else if(vm.dioType=='edit'){
            vm.updateUser()
          }else if(vm.dioType=='batch'){
            vm.userBatchUpdate()
          }
        } else {return false}
      })
    },
    //关闭弹窗
    closeDio (formEl){
      // vm.$nextTick(() => {
        vm.$refs[formEl].resetFields()
      // })
      vm.userIds = []
      // vm.inform.roleIds = []
      vm.inform ={
        id:'',
        account:'',//账号
        pwd:'',//密码
        confirmPwd:'',//确认密码
        sipAccount:'',//SIP账号
        sipPwd:'',//SIP密码
        roleIds:[],//角色
        depaId:'',//企业IDs
        isEnable:0,//是否启用
        isLocked:0,//临时冻结
        // loginSupport:'SINGLE',//登录支持
        remark:'',//备注
        userType:'',//用户类型
      }
      vm.isDio = false
    },
    //获取角色列表
    async roleListFun(){
      const res = await apiUrl.roleList({})
      if(res?.code==200){
        vm.roleList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //查询未被绑定的成员列表
    async userListUnbind(){
      const res = await apiUrl.userListUnbind()
      if(res?.code==200){
        vm.userList = res.data
      }else{
        vm.$message.error(res.message)
      }
    },
    //获取企业树列表
    async getDepartmentTree (){
      const res = await apiUrl.getDepartmentTree()
      if(res?.code==200){
        vm.departList = res.data
        delChildren(vm.departList)
      }else{
        vm.$message.error(res.message)
      }
    },
    //批量修改企业、角色
    async userBatchUpdate(){
      let dataObj = {
        'userIds': vm.userIds,//用户ids
        'roleIds': vm.inform.roleIds,//角色ids
        'depaId': vm.inform.depaId,//企业ids
      }
      const res = await apiUrl.userBatchUpdate(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //新增
    async addUserInsert(){
      let dataObj = {
        'account': vm.inform.account,//账号
        'pwd':vm.$md5(vm.inform.pwd),//密码
        'sipAccount': vm.inform.sipAccount,//SIP账号
        'sipPwd': vm.inform.sipPwd,//SIP密码
        'roleIds': vm.inform.roleIds,//角色ids
        'depaId': vm.inform.depaId,//企业ids
        // 'loginSupport': vm.inform.loginSupport,//登录支持
        'isEnable': vm.inform.isEnable,//是否启用
        'isLocked': vm.inform.isLocked,//是否临时冻结
        'remark': vm.inform.remark,//备注
        'userType': vm.inform.userType,//类型
      }
      const res = await apiUrl.addUserInsert(dataObj)
      if(res?.code==200){
        vm.$message.success('创建成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    },
    //修改
    async updateUser(){
      let dataObj = {
        'id':vm.inform.id,
        'account': vm.inform.account,//账号
        'sipAccount': vm.inform.sipAccount,//SIP账号
        'sipPwd': vm.inform.sipPwd,//SIP密码
        'roleIds': vm.inform.roleIds,//角色ids
        'depaId': vm.inform.depaId,//企业ids
        // 'loginSupport': vm.inform.loginSupport,//登录支持
        'isEnable': vm.inform.isEnable,//是否启用
        'isLocked': vm.inform.isLocked,//是否临时冻结
        'remark': vm.inform.remark,//备注
        'userType': vm.inform.userType,//类型
      }
      const res = await apiUrl.updateUser(dataObj)
      if(res?.code==200){
        vm.$message.success('修改成功！')
        vm.$emit('addEditSucc')
        vm.closeDio('dioform')
      }else{
        vm.$message.error(res.message)
      }
    }
  }
}
</script>
<style lang='scss'>
.userAddEdit{

}
</style>